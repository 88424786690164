import React, {useState} from "react";
import {Box, Flex} from "@chakra-ui/react";

const Footer = () => {

  const [year] = useState(() => {
    const date = new Date();
    return date.getFullYear();
  })

  return (
    <Box backgroundColor="rgba(3,7,18,1)">
      <footer>
        <Flex
          margin="0 auto"
          px={12}
          color="white"
          justifyContent="center"
          alignItems="center"
          maxWidth="1024px"
          height={16}
        >
          {/* <p>Leonel • © {year}</p> */}
          <p>Leo Web Dev • © {year}</p>
        </Flex>
      </footer>
    </Box>
  );
};
export default Footer;
