import {useState, useEffect, RefObject, useRef} from 'react';
import debounce from 'lodash.debounce';

// Define type for hook parameters
const useDetectOverflow = <T extends HTMLElement>(
	ref: RefObject<T>,
	delay: number = 300,
	minWidth: string = '48em'
): boolean => {
	const [hasOverflow, setHasOverflow] = useState<boolean>(false);
	const isAboveBreakpointRef = useRef<boolean>(false);


	useEffect(() => {
		const mediaQuery = window.matchMedia(`(min-width: ${minWidth})`);

		const handleMediaQueryChange = (e: MediaQueryListEvent) => {
			isAboveBreakpointRef.current = e.matches;
		};

		isAboveBreakpointRef.current = mediaQuery.matches;

		mediaQuery.addEventListener('change', handleMediaQueryChange);

		return () => {
			mediaQuery.removeEventListener('change', handleMediaQueryChange);
		};
	}, [minWidth]);

	useEffect(() => {
		const checkOverflow = () => {
			if (ref.current && isAboveBreakpointRef.current) {
				setHasOverflow(ref.current.scrollHeight > ref.current.clientHeight);
			}
		};

		const debouncedCheckOverflow = debounce(checkOverflow, delay);

		// Initial check
		checkOverflow();

		// Check overflow when the window is resized
		window.addEventListener('resize', debouncedCheckOverflow);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener('resize', debouncedCheckOverflow);
			debouncedCheckOverflow.cancel();
		};
	}, [ref, delay]);

	return hasOverflow;
};

export default useDetectOverflow;