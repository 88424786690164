// import {BrowserRouter} from "react-router-dom";
import {ChakraProvider} from "@chakra-ui/react";
import Header from "./components/Header";
import LandingSection from "./components/LandingSection";
import ProjectsSection from "./components/ProjectsSection";
// import ContactMeSection from "./components/ContactMeSection";
import Footer from "./components/Footer";
import {AlertProvider} from "./context/alertContext";
import {ModalProvider} from "./context/ModalContext";
import Alert from "./components/Alert";
import {AppProvider} from "./context/AppContext";
import {Loader} from "./components/Loader/Loader";
import theme from "./theme/theme";
import ModalYoutube from "components/ModalYoutube";




function App() {
	return (
		// <BrowserRouter>
		<ChakraProvider theme={theme}>
			<AppProvider>
				<Loader>
					<ModalProvider>
						<AlertProvider>
							<main style={{
								// background: "rgb(3,7,18)",
								// backgroundImage: "linear-gradient(180deg, rgba(3,7,18,1) 0%, rgba(31,41,55,1) 100%)"
							}}>
								<Header />
								<LandingSection />
								<ProjectsSection />
								{/* <ContactMeSection /> */}
								<Footer />
								<Alert />
								<ModalYoutube />
							</main>
						</AlertProvider>
					</ModalProvider>
				</Loader>
			</AppProvider>
		</ChakraProvider>
		// </BrowserRouter>
	);
}

export default App;
