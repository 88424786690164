import React from 'react';
import {Box, Text, Flex} from "@chakra-ui/react";
import {Pagination} from './Pagination';
// import {motion, AnimatePresence} from "framer-motion";


function Grid({items, perPage, page, onPageClick, children}) {

	const totalItems = items.length;
	const totalPages = Math.ceil(totalItems / perPage);
	const offset = perPage * page;
	const itemsPaged = items.slice(offset, offset + perPage);



	// console.log('items: %o, perPage: %o, page: %o', items, perPage, page);

	return (
		<div className='grid' style={{width: '100%'}}>
			{
				totalItems ? (
					<>
						{/* Top pagination */}
						<Flex className="info-top" style={{margin: '-10px 0px 20px'}} justifyContent={'flex-end'} alignItems="center" columnGap={7} rowGap={3} width="100%" flexWrap='wrap'>
							<Text fontSize='15px'>Viewing {offset + itemsPaged.length} of {totalItems}</Text>
							<Pagination spacing={2} prevNextOnly={true} visiblePages={0} total={totalPages} current={page} onClick={onPageClick} />
						</Flex>

						{/* <Box flex={true} style={{margin: '-10px 0 20px 0', textAlign: 'right'}}>Viewing {offset + itemsPaged.length} of {totalItems}
							<Pagination prevNextOnly={true} visiblePages={0} total={totalPages} current={page} onClick={onPageClick} />
						</Box> */}
			
						<Box
							display="flex"
							flexWrap='wrap'
							css={{'--gap': '30px'}}
							columnGap={'var(--gap)'}
							rowGap={{base: '30px', lg: '40px'}}
							sx={{
								'--items-per-row': {
									base: '1',
									md: '2',
									lg: '3'
								}
							}}

							// display="grid"
							// gridTemplateColumns={{base: '1fr', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)'}}
							// gridColumnGap={{base: 6, md: 6, lg: 8}}
							// gridRowGap={{base: 8, md: 8, lg: 12}}
						>
							{/* {itemsPaged.map((item, idx) => children(item))} */}
							{itemsPaged.map((item, idx) => {
								return React.cloneElement(children(item), {
									key: item.id || idx,
									style: {"--delay": 0.5 * idx / 10 + "s"}
								})
							})}
						</Box>
						<Pagination mt={{base: '40px'}} total={totalPages} current={page} onClick={onPageClick} />
					</>

				) : (
					<Text className='no-projects' fontSize='2xl' style={{
						textAlign: 'center',
						width: '100%',
						marginTop: '110px'
					}}>No projects to display</Text>
				)}
		</div>
	)
}

export default Grid;