import {useEffect, useRef} from 'react';

interface ScrollOptions {
	sensitivity?: number;
	maxSpeed?: number;
	minWidth?: string;
}

const DEFAULT_OPTIONS = {
	sensitivity: 0.24,  // Adjust for faster/slower scrolling
	maxSpeed: 16,       // Maximum scroll speed
	minWidth: '0px',
}

export const useHorizontalScroll = (options: ScrollOptions = {}) => {
	const containerRef = useRef<HTMLDivElement | null>(null);
	const scrollingRef = useRef(false);
	const mouseXRef = useRef(0);
	const isAboveBreakpointRef = useRef<boolean>(false);


	const {
		sensitivity,
		maxSpeed,
		minWidth
	} = {...DEFAULT_OPTIONS, ...options};


	useEffect(() => {
		const mediaQuery = window.matchMedia(`(min-width: ${minWidth})`);

		const handleMediaQueryChange = (e: MediaQueryListEvent) => {
			isAboveBreakpointRef.current = e.matches;
		};

		isAboveBreakpointRef.current = mediaQuery.matches;

		mediaQuery.addEventListener('change', handleMediaQueryChange);

		return () => {
			mediaQuery.removeEventListener('change', handleMediaQueryChange);
		};
	}, [minWidth]);

	
	useEffect(() => {
		const container = containerRef.current;
		if (!container) return;

		const handleMouseMove = (e: MouseEvent) => {

			if (!isAboveBreakpointRef.current) return;

			// console.log('mouse move');

			const containerRect = container.getBoundingClientRect();
			const mouseX = e.clientX - containerRect.left;
			mouseXRef.current = mouseX;

			if (mouseX >= 0 && mouseX <= containerRect.width) {
				scrollingRef.current = true;
			} else {
				scrollingRef.current = false;
			}
		};

		const handleMouseLeave = () => {
			scrollingRef.current = false;
		};

		const scroll = () => {
			if (!scrollingRef.current || !container || !isAboveBreakpointRef.current) return;

			// console.log('scroll');

			const containerWidth = container.offsetWidth;
			const mouseX = mouseXRef.current;
			// const containerCenter = containerWidth / 2;

			let scrollAmount = 0;

			// Simplified and symmetrical intensity calculation
			const intensity = (mouseX / containerWidth) * 2 - 1; // This gives us -1 to 1
			scrollAmount = maxSpeed * intensity;

			// For debugging
			// console.log({
			// 	mouseX,
			// 	containerWidth,
			// 	intensity,
			// 	scrollAmount,
			// 	amount: scrollAmount * sensitivity
			// });

			container.scrollLeft += scrollAmount * sensitivity;
		};

		let animationFrameId: number;
		const animate = () => {
			scroll();
			animationFrameId = requestAnimationFrame(animate);
		};
		animate();

		container.addEventListener('mousemove', handleMouseMove);
		container.addEventListener('mouseleave', handleMouseLeave);

		return () => {
			cancelAnimationFrame(animationFrameId);
			container.removeEventListener('mousemove', handleMouseMove);
			container.removeEventListener('mouseleave', handleMouseLeave);
		};
	}, [sensitivity, maxSpeed]);

	return containerRef;
};